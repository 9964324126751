import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { setOrigin, setConfiguration, setContext, POST } from 'ecpfs-authority'
import {
	SystemMessagesContextProvider,
	EventsContextProvider
} from 'ecpfs-react-data-driven-components'
import { sendAnalytics } from './utils/sendAnalytics'
import './version.js'
import { config } from '../package.json'
import AfsApp from './AfsApp'
import { customRequest } from './customRequest'

// lazily loaded css wrapper components
// this is meant to avoid having all css (myaccounts, liq, afs) in one build
const AfsAppCssWrapper = React.lazy(() => import('./AfsAppCssWrapper'))

const { apiContext, senderMessageId, senderApplicationId } = config
const COMPANY_ID = 'companyId'
const USER_ID = 'userId'
setOrigin(window.location)
let concatenatedSenderId = senderApplicationId
console.log('/servicing/perusal context added')
if (window.location.pathname === '/perusal') {
	let url = new URL(window.location.href)
	let query = url.searchParams
	if (query.has(USER_ID) && query.has(COMPANY_ID)) {
		let userId = query.get(USER_ID)
		let companyId = query.get(COMPANY_ID)
		concatenatedSenderId += `-${userId}-${companyId}`
		console.log('Modified sender id: ' + concatenatedSenderId)
	}
	setContext('/servicing/perusal')
} else {
	setContext(`${apiContext}/`)
}
setConfiguration({
	senderMessageId,
	senderApplicationId: concatenatedSenderId
})

customRequest(POST)

export function getApp() {
	return AfsApp
}

function ChooseApp({ children }) {
	return (
		<>
			<React.Suspense fallback={<></>}>{<AfsAppCssWrapper />}</React.Suspense>
			{children}
		</>
	)
}

export function renderDOM(store) {
	const AppToRender = getApp()
	ReactDOM.render(
		<ChooseApp>
			<Root useStore={store}>
				<AppToRender />
			</Root>
		</ChooseApp>,
		document.getElementById('root')
	)
}

export function Root({ useStore, children }) {
	return (
		<div className="DigitalServicing">
			<Provider store={useStore}>
				<AnalyticsWrapper children={children} />
			</Provider>
		</div>
	)
}

function AnalyticsWrapper({ children }) {
	return (
		<EventsContextProvider onNotify={event => sendAnalytics(event)}>
			<SystemMessagesContextProvider>{children}</SystemMessagesContextProvider>
		</EventsContextProvider>
	)
}
