import React, { useState, useEffect, useContext, useRef } from 'react'
import {
	RenderComponents,
	BasicNoContent,
	BasicText,
	BasicSystemMessages,
	BasicLabelInput,
	SystemMessagesContext
} from 'ecpfs-react-data-driven-components'
import { Panel, Feedback, MessageBox } from '@wf/ria'
import { IconVerify, IconConfirmation, IconInformationalLight } from '@wf/ria-icons'
import { FormattedMessage } from 'react-intl'
import CustomCustomerNumberColumn from './CustomCustomerNumberColumn'
import CustomPrintArea from './CustomPrintArea'
import { CustomWaitMessage } from './CustomWaitMessage'
import { useSelector, useDispatch } from 'react-redux'
import { getAccountAndBalances } from '../actions/billingStatements'
import CustomSourceAccountTable from './CustomSourceAccountTable'
import { updateSystemMessage } from '../actions/systemMessages'
import { blurFields } from '../utils/blurFields.js'
import moment from 'moment-timezone'

export function PaydownsAndAdvancesPanel({
	show,
	onClose,
	rowData,
	formatMessage,
	dropdownValue,
	commitmentDetails,
	facilityKey,
	showAccountsLoading,
	onGetCommitmentDetails,
	onGetPayDownsAndAdvancesData,
	onGetPayDownsConfirmationViewData
}) {
	if (show === false) return <BasicNoContent />
	return (
		<PaydownAdvancePanel
			onClose={onClose}
			rowData={rowData}
			formatMessage={formatMessage}
			dropdownValue={dropdownValue}
			commitmentDetails={commitmentDetails}
			facilityKey={facilityKey}
			showAccountsLoading={showAccountsLoading}
			onGetCommitmentDetails={onGetCommitmentDetails}
			onGetPayDownsAndAdvancesData={onGetPayDownsAndAdvancesData}
			onGetPayDownsConfirmationViewData={onGetPayDownsConfirmationViewData}
		/>
	)
}

function PanelContentRow({ labelId, value, showContent = true }) {
	if (!showContent) {
		return <BasicNoContent />
	}
	return (
		<div className="PanelFields">
			<dt className="TextAlignRight">
				<FormattedMessage id={labelId} />
			</dt>
			<dd className="TextAlignLeft">{value}</dd>
		</div>
	)
}

function getAmount(stringValue = '') {
	return Number(stringValue.replace(/,/g, ''))
}

function PaydownAdvancePanel({
	onClose,
	rowData,
	formatMessage,
	dropdownValue,
	commitmentDetails,
	facilityKey,
	showAccountsLoading,
	onGetCommitmentDetails,
	onGetPayDownsAndAdvancesData,
	onGetPayDownsConfirmationViewData
}) {
	const { item = {} } = rowData
	const {
		outstandingPrincipalBalance,
		amountAvailableUSD,
		obligationNickname,
		obligationId,
		customer,
		advanceRestriction,
		hlaAdvanceRestrictionCode,
		hlaAdvanceRestrictionFlag,
		auNum,
		participationFlag,
		amountAvailable
	} = item
	const [paymentsFeedbackMessage, setPaymentsFeedbackMessage] = useState()
	const [openPayPanel, setOpenPayPanel] = useState(true)
	const [openVerifyPanel, setOpenVerifyPanel] = useState(false)
	const [openConfirmationPanel, setOpenConfirmationPanel] = useState(false)
	const [openErrorPanel, setOpenErrorPanel] = useState(false)
	const [selectedAccount, setSelectedAccount] = useState([])
	const [showInternalSiteMessage, setshowInternalSiteMessage] = useState(false)
	const [paymentAmount, setPaymentAmount] = useState('')
	const [paymentAmountKey, setPaymentAmountKey] = useState(0)
	const { systemMessages } = useSelector(state => state.systemMessages)
	const { clearMessages } = useContext(SystemMessagesContext)
	const { fromInternalSite = false } = useSelector(state => state.navigator)
	const [initialLoad, setInitialLoad] = useState(true)

	const paydowAdvancePanelRef = useRef(null)
	const loadingLabel = formatMessage({
		id: 'obligation-details-page.label.loading'
	})

	function onValueChange({ value }) {
		setPaymentAmount(value)
	}
	function onBlurred() {
		const paymentAmountInputRegex = '^[0-9\\.,]+$'
		const amountFieldRegex = new RegExp(paymentAmountInputRegex)
		if (amountFieldRegex.test(paymentAmount.trim())) {
			setPaymentAmount(formatPaymentAmount().trim())
			if (paymentAmount !== formatPaymentAmount().trim()) {
				setPaymentAmountKey(paymentAmountKey + 1)
			}
		}
	}
	const { accounts, effectiveDate = '' } = useSelector(state => state.billingStatements)
	const { payDownsConfirmationViewData = [] } = useSelector(state => state.commitment)
	const dispatch = useDispatch()
	function formatPaymentAmount() {
		const amount = getAmount(paymentAmount.split('.')[0])
		const amountInDecimal = paymentAmount.split('.')[1]
		const formatAmount = Number(amount).toLocaleString() + '.' + amountInDecimal
		if (
			paymentAmount === '' ||
			paymentAmount.indexOf(' ') !== -1 ||
			paymentAmount.split('.').length > 2 ||
			(amountInDecimal && amountInDecimal.indexOf(',') !== -1)
		) {
			return paymentAmount
		}
		if (
			amountInDecimal &&
			amountInDecimal.length > 1 &&
			Number(amountInDecimal?.substring(2)) !== 0
		) {
			return formatAmount
		} else if (amountInDecimal && amountInDecimal.length === 1) {
			return formatAmount + '0'
		} else {
			const amount = getAmount(paymentAmount)?.toFixed(2)
			return Number(amount.split('.')[0]).toLocaleString() + '.' + amount.split('.')[1]
		}
	}

	useEffect(() => {
		setInitialLoad(showAccountsLoading)
	}, [initialLoad, showAccountsLoading])

	useEffect(() => {
		const { encryptedKey } = item
		dispatch(getAccountAndBalances(encryptedKey))
	}, [dispatch, item])

	useEffect(() => {
		if (paymentAmountKey) blurFields('.PaydownAdvancePanel .BasicLabelInput input')
	}, [paymentAmountKey, selectedAccount])

	function onSubmit() {
		const amountInDecimal = paymentAmount.split('.')[1]
		if (
			!getAmount(paymentAmount) ||
			!Number(getAmount(paymentAmount)) ||
			(amountInDecimal && amountInDecimal.length > 2) ||
			(amountInDecimal && amountInDecimal.indexOf(',') !== -1)
		) {
			blurFields('.PaydownAdvancePanel .BasicLabelInput input')
		} else if (
			(paymentAmount && parseFloat(getAmount(paymentAmount)) < 0.01) ||
			(outstandingPrincipalBalance &&
				dropdownValue === 'payDownPrincipal' &&
				getAmount(paymentAmount) > getAmount(outstandingPrincipalBalance)) ||
			(amountAvailableUSD &&
				dropdownValue === 'advanceFunds' &&
				getAmount(paymentAmount) > getAmount(amountAvailableUSD))
		) {
			blurFields('.PaydownAdvancePanel .BasicLabelInput input')
		} else {
			clearMessages()
			dispatch(updateSystemMessage([]))
			setOpenVerifyPanel(true)
			setOpenPayPanel(false)
		}
	}
	function onClosePaydown() {
		clearMessages()
		dispatch(updateSystemMessage([]))
		onClose()
	}
	function onConfirm() {
		const additionalAdvanceParams = {
			advanceRestriction,
			hlaAdvanceRestrictionCode,
			hlaAdvanceRestrictionFlag,
			auNum,
			participationFlag,
			amountAvailable,
			customer
		}
		if (fromInternalSite) {
			setOpenVerifyPanel(false)
			setOpenErrorPanel(true)
			setshowInternalSiteMessage(true)
		} else {
			const selectedAccountEncryptedKey = getSelectedAccountEncryptedKey()
			setOpenVerifyPanel(false)
			const responseStatus = value => {
				if (value) {
					setOpenConfirmationPanel(true)
				} else {
					setOpenErrorPanel(true)
				}
			}
			const paymentTotal = formatPaymentAmount()
			onGetPayDownsConfirmationViewData(
				dropdownValue,
				obligationId,
				paymentTotal,
				selectedAccountEncryptedKey,
				responseStatus,
				additionalAdvanceParams
			)
		}
	}
	function getSelectedAccountEncryptedKey() {
		if (accounts.length > 0) {
			const accountEncryptedKey = accounts.filter(
				account => account.encryptedKey === selectedAccount[0]
			)[0]?.encryptedKey
			return accountEncryptedKey
		}
	}
	function onVerifyEdit() {
		clearMessages()
		dispatch(updateSystemMessage([]))
		setPaymentsFeedbackMessage()
		setOpenVerifyPanel(false)
		setshowInternalSiteMessage(false)
		setOpenPayPanel(true)
	}
	function onCloseVerifyPanel() {
		clearMessages()
		dispatch(updateSystemMessage([]))
		setOpenVerifyPanel(false)
		onClose()
	}
	function onCloseConfirmationpanel() {
		setOpenConfirmationPanel(false)
		clearMessages()
		dispatch(updateSystemMessage([]))
		onClose()
		onGetCommitmentDetails(facilityKey)
		onGetPayDownsAndAdvancesData(facilityKey, dropdownValue)
	}
	function onCloseErrorPanel() {
		setOpenErrorPanel(false)
		clearMessages()
		dispatch(updateSystemMessage([]))
		onClose()
	}
	const sourceAccountNumber = getSourceAccountNumber()
	function getSourceAccountNumber() {
		if (accounts.length > 0) {
			const accountNumber = accounts.filter(
				account => account.encryptedKey === selectedAccount[0]
			)[0]?.maskedAccountNumber
			return accountNumber
		}
	}
	const customToolbar = {
		actions: [
			{
				tag: 'BasicAction',
				className: 'SubmitPayment',
				label: formatMessage({
					id: 'loan-billing.statements-page.modal.submit'
				}),
				onClick: onSubmit,
				disabled: false
			},
			{
				tag: 'BasicAction',
				className: 'CancelPayment',
				display: 'standard',
				label: formatMessage({
					id: 'loan-billing.statements-page.modal.cancel'
				}),
				onClick: onClosePaydown,
				disabled: false
			}
		]
	}
	const modelTitle =
		dropdownValue === 'payDownPrincipal'
			? 'commitment-details.paydown-panel.modal.paydown-title'
			: 'commitment-details.advances-panel.modal.advances-title'

	const onSelectedAmount = data => {
		setSelectedAccount(data)
	}
	const ariaCaption =
		dropdownValue === 'payDownPrincipal'
			? formatMessage({
					id: 'pay-down-principal-panel.label.source-account-aria-caption'
			  })
			: formatMessage({
					id: 'advance-funds-panel.label.destination-account-aria-caption'
			  })

	const showObligationInfo =
		commitmentDetails?.customerName
			?.replace(/\s+/g, '')
			?.localeCompare(customer?.replace(/\s+/g, '')) === 0
			? false
			: true

	return (
		<>
			<Panel
				className="PaydownAdvancePanel"
				open={openPayPanel}
				onClose={onClosePaydown}
				title={formatMessage({
					id: modelTitle
				})}
				footer={<RenderComponents list={customToolbar.actions} />}
				elementToFocusOnClose=".PaydownAdvancePanel button"
			>
				<div ref={paydowAdvancePanelRef}>
					<PaymentsMessage
						paymentsFeedbackMessage={paymentsFeedbackMessage || systemMessages?.[0]}
					/>
					<BasicText
						required={true}
						text={formatMessage({ id: 'loan-billing.statements-page.modal.required' })}
					/>
					<dl className="wf-dl-inline">
						<PanelContentRow
							labelId="commitment-details.paydown-panel.modal.column.label.commitment"
							value={
								commitmentDetails.facilityNickname
									? `${commitmentDetails?.facilityNickname} ${commitmentDetails?.facilityNumber}`
									: commitmentDetails?.facilityNumber
							}
						/>
						<PanelContentRow
							labelId="commitment-details.paydown-panel.modal.column.label.commitment-customer"
							value={<CommitmentCustomerValue commitmentDetails={commitmentDetails} />}
						/>
						<PanelContentRow
							labelId="commitment-details.paydown-panel.modal.column.label.obligation"
							value={obligationNickname ? `${obligationNickname} ${obligationId}` : obligationId}
						/>
						<CustomAmountFields
							dropdownValue={dropdownValue}
							paymentAmount={paymentAmount}
							item={item}
							formatMessage={formatMessage}
							onValueChange={onValueChange}
							paymentAmountKey={paymentAmountKey}
							onBlurred={onBlurred}
						/>
					</dl>
					<div>
						<CustomSourceAccountTable
							formatMessage={formatMessage}
							accounts={accounts}
							selectedAccount={selectedAccount}
							setSelectedAccount={onSelectedAmount}
							label={
								dropdownValue !== 'payDownPrincipal'
									? 'commitment-details.paydown-panel.modal.column.label.destination-account-header'
									: null
							}
							ariaCaption={ariaCaption}
						/>
						<CustomWaitMessage
							isLoading={initialLoad}
							loadingMessage={loadingLabel}
							containerNode={paydowAdvancePanelRef}
						/>
					</div>
					<Disclaimer isPayPanel={true} dropdownValue={dropdownValue} />
				</div>
			</Panel>
			<VerifyPanel
				rowData={rowData}
				isOpen={openVerifyPanel}
				onClose={onCloseVerifyPanel}
				onConfirm={onConfirm}
				formatMessage={formatMessage}
				formatPaymentAmount={formatPaymentAmount}
				onVerifyEdit={onVerifyEdit}
				sourceAccountNumber={sourceAccountNumber}
				dropdownValue={dropdownValue}
				commitmentDetails={commitmentDetails}
				informationMessage={paymentsFeedbackMessage}
				effectiveDate={effectiveDate}
				showObligationInfo={showObligationInfo}
			/>
			<ConfirmationPanel
				rowData={rowData}
				isOpen={openConfirmationPanel}
				onClose={onCloseConfirmationpanel}
				formatMessage={formatMessage}
				formatPaymentAmount={formatPaymentAmount}
				sourceAccountNumber={sourceAccountNumber}
				dropdownValue={dropdownValue}
				commitmentDetails={commitmentDetails}
				payDownsConfirmationViewData={payDownsConfirmationViewData}
				showObligationInfo={showObligationInfo}
			/>
			<ErrorPanel
				isOpen={openErrorPanel}
				onClose={onCloseErrorPanel}
				formatMessage={formatMessage}
				showInternalSiteMessage={showInternalSiteMessage}
				systemMessages={systemMessages}
			/>
		</>
	)
}

function InternalSiteMessage({ showInternalSiteMessage, systemMessage }) {
	if (showInternalSiteMessage) {
		return (
			<div className="InternalSiteMessage">
				<FormattedMessage id="loan-billing.statements-page.modal.verify.internal-site.message" />
			</div>
		)
	} else if (systemMessage) {
		return <div className="InternalSiteMessage">{systemMessage.message}</div>
	}
	return <BasicNoContent />
}

function CommitmentCustomerValue({ commitmentDetails }) {
	if (commitmentDetails.borrowerid) {
		return (
			<div>
				{commitmentDetails?.customerName}
				<div>{`...${commitmentDetails.borrowerid.substr(-4)}`}</div>
			</div>
		)
	} else {
		return <div>{commitmentDetails?.customerName}</div>
	}
}

function CustomAmountFields({
	dropdownValue,
	item,
	formatMessage,
	onValueChange,
	paymentAmountKey,
	onBlurred,
	paymentAmount
}) {
	const paymentAmountInputRegex = '^[0-9\\.,]+$'
	function validatePaymentAmount(amount) {
		const value = amount.trim()
		const amountFieldRegex = new RegExp(paymentAmountInputRegex)
		const amountInDecimal = value.split('.')[1]
		if (
			(amountInDecimal && Number(amountInDecimal?.substring(2)) !== 0) ||
			(amountInDecimal && amountInDecimal.indexOf(',') !== -1)
		) {
			return formatMessage({
				id: 'commitment-details.paydown-panel.modal.label.payment-amount-input-validation'
			})
		}
		if (!amountFieldRegex.test(value)) {
			return formatMessage({
				id: 'commitment-details.paydown-panel.modal.label.payment-amount-input-validation'
			})
		}
		if (value && parseFloat(getAmount(value)) < 0.01) {
			return formatMessage({
				id: 'commitment-details.paydown-panel.modal.label.payment-amount-input-validation'
			})
		}
		if (
			value &&
			dropdownValue === 'payDownPrincipal' &&
			item?.outstandingPrincipalBalance &&
			getAmount(value) > getAmount(item?.outstandingPrincipalBalance)
		) {
			return formatMessage({
				id: 'commitment-details-page.modal.paydown.paymentamount.check.validation'
			})
		}
		if (
			value &&
			dropdownValue === 'advanceFunds' &&
			item?.amountAvailableUSD &&
			getAmount(value) > getAmount(item?.amountAvailableUSD)
		) {
			return formatMessage({
				id: 'commitment-details-page.modal.paydown.advanceamount.check.validation'
			})
		}
		if (value.toString().split('.').length > 2) {
			return formatMessage({
				id: 'commitment-details.paydown-panel.modal.label.payment-amount-input-validation'
			})
		}
	}

	if (dropdownValue === 'payDownPrincipal') {
		return (
			<>
				<PanelContentRow
					labelId="commitment-details.paydown-panel.modal.column.label.outstanding-principal"
					value={`${item?.outstandingPrincipalBalance} ${formatMessage({
						id: 'view-interest-panel.label.currency-usd'
					})}`}
				/>
				<div className="wf-row">
					<div className="PaydownLabelInput  wf-col--tab-6 wf-col--mob-10">
						<BasicLabelInput
							required={true}
							key={paymentAmountKey}
							name="paymentAmount"
							label={formatMessage({
								id: 'commitment-details.paydown-panel.modal.label.payment-amount'
							})}
							value={paymentAmount}
							onChanged={onValueChange}
							onBlurred={onBlurred}
							validations={{
								[`${formatMessage({
									id: 'commitment-details.input.field-is-required.message'
								})}`]: 'required',
								[formatMessage({
									id: 'commitment-details.paydown-panel.modal.label.payment-amount-input-validation'
								})]: validatePaymentAmount
							}}
						/>
					</div>
					<div className="wf-col PaddedCol">
						{formatMessage({
							id: 'view-interest-panel.label.currency-usd'
						})}
					</div>
				</div>
			</>
		)
	} else {
		return (
			<>
				<PanelContentRow
					labelId="commitment-details.advances-panel.modal.column.label.amount-available"
					value={`${item?.amountAvailableUSD} ${formatMessage({
						id: 'view-interest-panel.label.currency-usd'
					})}`}
				/>
				<div className="wf-row">
					<div className="PaydownLabelInput wf-col--tab-6 wf-col--mob-10">
						<BasicLabelInput
							required={true}
							key={paymentAmountKey}
							name="paymentAmount"
							label={formatMessage({
								id: 'commitment-details.advances-panel.modal.column.label.advance-amount'
							})}
							value={paymentAmount}
							onChanged={onValueChange}
							onBlurred={onBlurred}
							validations={{
								[`${formatMessage({
									id: 'commitment-details.input.field-is-required.message'
								})}`]: 'required',
								[formatMessage({
									id: 'commitment-details.paydown-panel.modal.label.payment-amount-input-validation'
								})]: validatePaymentAmount
							}}
						/>
					</div>
					<div className="wf-col PaddedCol">
						{formatMessage({
							id: 'view-interest-panel.label.currency-usd'
						})}
					</div>
				</div>
			</>
		)
	}
}

function PaymentsMessage({ paymentsFeedbackMessage }) {
	if (!paymentsFeedbackMessage) {
		return <BasicNoContent />
	}
	return <BasicSystemMessages messages={[paymentsFeedbackMessage]} />
}

function VerifyPanel({
	rowData,
	isOpen,
	onClose,
	onConfirm,
	formatMessage,
	onVerifyEdit,
	formatPaymentAmount,
	sourceAccountNumber,
	dropdownValue,
	commitmentDetails,
	effectiveDate,
	showObligationInfo
}) {
	const { item } = rowData
	const customToolbar = {
		actions: [
			{
				tag: 'BasicAction',
				className: 'ConfirmButton',
				label: formatMessage({
					id: 'commitment-details.paydown-panel.modal.button.confirm'
				}),
				onClick: onConfirm
			},
			{
				tag: 'BasicAction',
				className: 'VerifyEditButton',
				display: 'standard',
				label: formatMessage({
					id: 'loan-billing.statements-page.modal.edit'
				}),
				onClick: onVerifyEdit
			},
			{
				tag: 'BasicAction',
				className: 'VerifyCancelButton',
				display: 'chromeless',
				label: formatMessage({
					id: 'loan-billing.statements-page.modal.cancel'
				}),
				onClick: onClose
			}
		]
	}
	const verifyMessage =
		dropdownValue === 'payDownPrincipal'
			? 'commitment-details-page.modal.paydown.verify.informational.message'
			: 'commitment-details-page.modal.advance.verify.informational.message'
	return (
		<Panel
			className="VerifyPanel"
			onClose={onClose}
			open={isOpen}
			title={formatMessage({
				id: 'loan-billing.statements-page.verify.modal.header'
			})}
			footer={<RenderComponents list={customToolbar.actions} />}
			elementToFocusOnClose=".PaydownAdvancePanel button"
		>
			<Feedback icon={<IconVerify size="large" theme="information" />} size="large" role="alert">
				<p>
					<FormattedMessage id={verifyMessage} />
				</p>
			</Feedback>
			<dl className="wf-dl-inline">
				<PanelContentRow
					labelId="commitment-details.paydown-panel.modal.column.label.commitment"
					value={
						commitmentDetails.facilityNickname
							? `${commitmentDetails?.facilityNickname} ${commitmentDetails?.facilityNumber}`
							: commitmentDetails?.facilityNumber
					}
				/>
				<PanelContentRow
					labelId="commitment-details.paydown-panel.modal.column.label.commitment-customer"
					value={<CommitmentCustomerValue commitmentDetails={commitmentDetails} />}
				/>
				<PanelContentRow
					labelId="commitment-details.paydown-panel.modal.column.label.obligation"
					value={
						item.obligationNickname
							? `${item.obligationNickname} ${item?.obligationId}`
							: item?.obligationId
					}
				/>
				<PanelContentRow
					showContent={showObligationInfo}
					labelId="commitment-details.paydown-panel.modal.column.label.obligation-customer"
					value={<CustomCustomerNumberColumn rowData={rowData} />}
				/>
				<PanelContentRow
					labelId={
						dropdownValue === 'payDownPrincipal'
							? 'commitment-details.paydown-panel.modal.label.payment-amount'
							: 'commitment-details.advances-panel.modal.column.label.advance-amount'
					}
					value={`${formatPaymentAmount()} ${formatMessage({
						id: 'view-interest-panel.label.currency-usd'
					})}`}
				/>
				<PanelContentRow
					labelId={
						dropdownValue === 'payDownPrincipal'
							? 'loan-billing.statements-page.modal.verify.label.source-account'
							: 'commitment-details.paydown-panel.modal.label.destination-account'
					}
					value={sourceAccountNumber}
				/>
				<PanelContentRow
					labelId="commitment-details.paydown-panel.modal.label.estimated-effective-date"
					value={effectiveDate}
				/>
				<Disclaimer isPayPanel={false} dropdownValue={dropdownValue} />
			</dl>
		</Panel>
	)
}

function Disclaimer({ isPayPanel, dropdownValue }) {
	if (isPayPanel === true) {
		return (
			<div className="Disclaimer">
				<FormattedMessage
					id={
						dropdownValue === 'payDownPrincipal'
							? 'commitment-details-page.paydown.disclaimer'
							: 'commitment-details-page.advance.disclaimer'
					}
				/>
			</div>
		)
	}

	return (
		<div className="Disclaimer">
			<FormattedMessage id={'commitment-details-page.verify.confirm.disclaimer'} />
		</div>
	)
}

function ConfirmationPanel({
	rowData,
	isOpen,
	onClose,
	formatMessage,
	formatPaymentAmount,
	sourceAccountNumber,
	dropdownValue,
	commitmentDetails,
	payDownsConfirmationViewData,
	showObligationInfo
}) {
	const { item } = rowData
	const customToolbar = {
		actions: [
			{
				tag: 'CustomPrintButton',
				className: 'ConfirmationPrintButton',
				label: formatMessage({
					id: 'loan-billing.statements-page.modal.print'
				}),
				showIcon: false
			},
			{
				tag: 'BasicAction',
				className: 'ConfirmationCloseButton',
				display: 'chromeless',
				label: formatMessage({
					id: 'loan-billing.statements-page.modal.close'
				}),
				onClick: onClose
			}
		]
	}

	let confirmMessage = ''
	let confirmIcon = ''
	let nonSTPAdvance = false

	if (dropdownValue === 'payDownPrincipal') {
		confirmMessage = 'commitment-details-page.modal.paydown.confirmation.message'
		confirmIcon = <IconConfirmation size="large" theme="confirmation" />
	} else if (
		dropdownValue === 'advanceFunds' &&
		(item.hlaAdvanceRestrictionFlag && item.hlaAdvanceRestrictionFlag !== 39)
	) {
		confirmMessage = 'commitment-details-page.modal.advance.confirmation.message.new'
		confirmIcon = <IconInformationalLight size="large" theme="information" />
		nonSTPAdvance = true
	} else {
		confirmMessage = 'commitment-details-page.modal.advance.confirmation.message'
		confirmIcon = <IconConfirmation size="large" theme="confirmation" />
	}

	return (
		<CustomPrintArea>
			<Panel
				className="ConfirmationPanel"
				onClose={onClose}
				open={isOpen}
				title={formatMessage({
					id: 'loan-billing.statements-page.confirmation.modal.header'
				})}
				footer={<RenderComponents list={customToolbar.actions} />}
				elementToFocusOnClose=".PaydownAdvancePanel button"
			>
				<Feedback icon={confirmIcon} size="large" role="alert">
					<p>
						<FormattedMessage id={confirmMessage} />
					</p>
				</Feedback>
				<dl className="wf-dl-inline">
					<PanelContentRow
						labelId="commitment-details.paydown-panel.modal.column.label.commitment"
						value={
							commitmentDetails.facilityNickname
								? `${commitmentDetails?.facilityNickname} ${commitmentDetails?.facilityNumber}`
								: commitmentDetails?.facilityNumber
						}
					/>
					<PanelContentRow
						labelId="commitment-details.paydown-panel.modal.column.label.commitment-customer"
						value={<CommitmentCustomerValue commitmentDetails={commitmentDetails} />}
					/>
					<PanelContentRow
						labelId="commitment-details.paydown-panel.modal.column.label.obligation"
						value={
							item.obligationNickname
								? `${item.obligationNickname} ${item?.obligationId}`
								: item?.obligationId
						}
					/>
					<PanelContentRow
						showContent={showObligationInfo}
						labelId="commitment-details.paydown-panel.modal.column.label.obligation-customer"
						value={<CustomCustomerNumberColumn rowData={rowData} />}
					/>
					<PanelContentRow
						labelId={
							dropdownValue === 'payDownPrincipal'
								? 'commitment-details.paydown-panel.modal.label.payment-amount'
								: 'commitment-details.advances-panel.modal.column.label.advance-amount'
						}
						value={`${formatPaymentAmount()} ${formatMessage({
							id: 'view-interest-panel.label.currency-usd'
						})}`}
					/>
					<PanelContentRow
						labelId={
							dropdownValue === 'payDownPrincipal'
								? 'loan-billing.statements-page.modal.verify.label.source-account'
								: 'commitment-details.paydown-panel.modal.label.destination-account'
						}
						value={sourceAccountNumber}
					/>
					<PanelContentRow
						labelId="commitment-details.paydown-panel.modal.label.effective-date"
						value={payDownsConfirmationViewData[0]?.effectiveDate}
						showContent={!nonSTPAdvance}
					/>
					<PanelContentRow
						labelId="commitment-details.paydown-panel.modal.column.label.submission-date"
						value={`${moment()
							.tz('America/Los_Angeles')
							.format('MM/DD/YYYY hh:mm a')} PT`}
						showContent={!nonSTPAdvance}
					/>
					<Disclaimer isPayPanel={false} dropdownValue={dropdownValue} />
				</dl>
			</Panel>
		</CustomPrintArea>
	)
}

function ErrorPanel({ isOpen, onClose, formatMessage, showInternalSiteMessage, systemMessages }) {
	const customToolbar = {
		actions: [
			{
				tag: 'BasicAction',
				className: 'ErrorCancelButton',
				label: formatMessage({
					id: 'loan-billing.statements-page.modal.close'
				}),
				onClick: onClose
			}
		]
	}
	return (
		<MessageBox
			title={formatMessage({
				id: 'loan-billing.statements-page.modal.error'
			})}
			className="ErrorStatementPanel"
			onClose={onClose}
			open={isOpen}
			elementToFocusOnClose=".PayStatementButton button"
			footer={<RenderComponents list={customToolbar.actions} />}
			type={MessageBox.TYPES.ERROR}
		>
			<InternalSiteMessage
				showInternalSiteMessage={showInternalSiteMessage}
				systemMessage={systemMessages?.[0]}
			/>
		</MessageBox>
	)
}
